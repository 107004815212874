import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Checkout: React.FC = () => {
  const { id } = useParams();
  const [cartList, setCartList] = useState<any>([]);

  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
  let cartId: any = localStorage.getItem("cartId");
  const token = localStorage.getItem("token");

  const getCartList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          cartUuid: cartId ? cartId : lastActiveCartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "cartList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        setCartList(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCartList();
  }, []);

  useEffect(() => {
    // const getLocationDataForTax = () => {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //           async (position) => {
    //             const latitude = position.coords.latitude;
    //             const longitude = position.coords.longitude;

    //             try {
    //               const response = await axios.get(
    //                 `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    //               );

    //               const address = response.data.address;

    //               // Dynamically find the region: the key before "postcode"
    //               const addressKeys = Object.keys(address);
    //               const postcodeIndex = addressKeys.indexOf("postcode");
    //               const regionKey = addressKeys[postcodeIndex - 1]; // Key just before 'postcode'
    //               const region = address[regionKey] || ""; // Fallback to empty string if not found

    //               // Extract other data
    //               const zip = address.postcode; // Use postcode for zip
    //               const countryCode = address.country_code
    //                 ? address.country_code.toUpperCase()
    //                 : ""; // Convert to uppercase
    //               const city =
    //                 address.state_district || address.town || address.city; // Fallback to other address fields for city

    //               resolve({ city, region, countryCode, zip });
    //             } catch (error) {
    //               console.error("Error fetching geolocation:", error);
    //               reject(error);
    //             }
    //           },
    //           (error) => {
    //             console.error("Error getting geolocation:", error);
    //             reject(error);
    //           }
    //         );
    //       } else {
    //         console.error("Geolocation is not supported by this browser.");
    //         reject(new Error("Geolocation not supported"));
    //       }
    //     } catch (error) {
    //       console.error("Error fetching location data:", error);
    //       reject(error);
    //     }
    //   });
    // };

    // Call the function
    // (async () => {
    //   try {
    //     const locationData = await getLocationDataForTax();
    //     console.log(locationData);
    //     if (locationData) {
    fetchClientSecret();
    //     }
    //   } catch (error) {
    //     console.error("Error in getting location data:", error);
    //   }
    // })();

    async function fetchClientSecret() {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            orderId: id,
            cartUuid: cartId ? cartId : lastActiveCartId,
            success_url: `${process.env.REACT_APP_LOCALHOST_URL}/success/${id}`,
            cancel_url: `${process.env.REACT_APP_LOCALHOST_URL}/cancel/${id}`,
            organizationId: 1,
            action: "command",
            command: [
              {
                agent: "createCheckout",
                appName: "selfMaximized",
                folder: "payment",
              },
            ],
          },
          {
            headers: { "x-access-token": localStorage.getItem("token") },
          }
        );

        if (result?.data?.code === 1) {
          window.location.href = result?.data?.data?.sessionUrl;
        } else {
          console.error("Failed to fetch clientSecret:", result);
        }
      } catch (error) {
        console.error("Error fetching clientSecret:", error);
      }
    }
  }, [id]);

  return (
    <>
      <div className="processing-container">
        <div className="processing-content">
          <h1 className="processing-text letter_spacing">
            PROCESSING SECURE PAYMENT CONNECTION...
          </h1>
          <div className="processing-animation">
            <span className="bubble "></span>
            <span className="bubble ps-2"></span>
            <span className="bubble ps-2"></span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Checkout;
